
















import Vue from 'vue';
import COUNTRIES from '@/config/countires-list';

export default Vue.extend({
  name: 'countries-list',

  watch: {
    selectValue() {
      const countryIndex = COUNTRIES.findIndex((country) => this.selectValue === country.Land);
      if (countryIndex) {
        this.$emit('iso', COUNTRIES[countryIndex]['ISO-Code']);
        this.$emit('continent', COUNTRIES[countryIndex].Geltungsbereich);
      }
    },
    value(val) {
      this.selectValue = val;
      // this.$emit('input', this.value);
    },
  },
  props: {
    required: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectValue: this.value,
      isoCode: '',
      COUNTRIES,
    };
  },
  mounted() {
    this.selectValue = this.value;
    const countryIndex = COUNTRIES.findIndex((country) => this.selectValue === country.Land);
    if (countryIndex && countryIndex > -1) {
      this.$emit('continent', COUNTRIES[countryIndex].Geltungsbereich);
    }
  },
});
