export default [
  {
    Land: 'Deutschland',
    'ISO-Code': 'DE',
    Aussteuerung: '',
    Geltungsbereich: 'Deutschland',
  },
  {
    Land: 'Afghanistan',
    'ISO-Code': 'AF',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Ägypten',
    'ISO-Code': 'EG',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Albanien',
    'ISO-Code': 'AL',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Algerien',
    'ISO-Code': 'DZ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Andorra',
    'ISO-Code': 'AD',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Angola',
    'ISO-Code': 'AO',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Anguilla',
    'ISO-Code': 'AI',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Antarktis',
    'ISO-Code': 'AQ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Antigua und Barbuda',
    'ISO-Code': 'AG',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Äquatorial Guinea',
    'ISO-Code': 'GQ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Argentinien',
    'ISO-Code': 'AR',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Armenien',
    'ISO-Code': 'AM',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Aruba',
    'ISO-Code': 'AW',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Aserbaidschan',
    'ISO-Code': 'AZ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Äthiopien',
    'ISO-Code': 'ET',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Australien',
    'ISO-Code': 'AU',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Bahamas',
    'ISO-Code': 'BS',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Bahrain',
    'ISO-Code': 'BH',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Bangladesh',
    'ISO-Code': 'BD',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Barbados',
    'ISO-Code': 'BB',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Belgien',
    'ISO-Code': 'BE',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Belize',
    'ISO-Code': 'BZ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Benin',
    'ISO-Code': 'BJ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Bermudas',
    'ISO-Code': 'BM',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Bhutan',
    'ISO-Code': 'BT',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Birma',
    'ISO-Code': 'MM',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Bolivien',
    'ISO-Code': 'BO',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Bosnien-Herzegowina',
    'ISO-Code': 'BA',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Botswana',
    'ISO-Code': 'BW',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Bouvet Inseln',
    'ISO-Code': 'BV',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Brasilien',
    'ISO-Code': 'BR',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Britisch-Indischer Ozean',
    'ISO-Code': 'IO',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Brunei',
    'ISO-Code': 'BN',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Bulgarien',
    'ISO-Code': 'BG',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Burkina Faso',
    'ISO-Code': 'BF',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Burundi',
    'ISO-Code': 'BI',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Chile',
    'ISO-Code': 'CL',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'China',
    'ISO-Code': 'CN',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Christmas Island',
    'ISO-Code': 'CX',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Cook Inseln',
    'ISO-Code': 'CK',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Costa Rica',
    'ISO-Code': 'CR',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Dänemark',
    'ISO-Code': 'DK',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Djibuti',
    'ISO-Code': 'DJ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Dominika',
    'ISO-Code': 'DM',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Dominikanische Republik',
    'ISO-Code': 'DO',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Ecuador',
    'ISO-Code': 'EC',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'El Salvador',
    'ISO-Code': 'SV',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Elfenbeinküste',
    'ISO-Code': 'CI',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Eritrea',
    'ISO-Code': 'ER',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Estland',
    'ISO-Code': 'EE',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Falkland Inseln',
    'ISO-Code': 'FK',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Färöer Inseln',
    'ISO-Code': 'FO',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Fidschi',
    'ISO-Code': 'FJ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Finnland',
    'ISO-Code': 'FI',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Frankreich',
    'ISO-Code': 'FR',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'französisch Guyana',
    'ISO-Code': 'GF',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Französisch Polynesien',
    'ISO-Code': 'PF',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Französisches Süd-Territorium',
    'ISO-Code': 'TF',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Gabun',
    'ISO-Code': 'GA',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Gambia',
    'ISO-Code': 'GM',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Georgien',
    'ISO-Code': 'GE',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Ghana',
    'ISO-Code': 'GH',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Gibraltar',
    'ISO-Code': 'GI',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Grenada',
    'ISO-Code': 'GD',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Griechenland',
    'ISO-Code': 'GR',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Grönland',
    'ISO-Code': 'GL',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Großbritannien',
    'ISO-Code': 'UK',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Großbritannien (UK)',
    'ISO-Code': 'GB',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Guadeloupe',
    'ISO-Code': 'GP',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Guam',
    'ISO-Code': 'GU',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Guatemala',
    'ISO-Code': 'GT',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Guinea',
    'ISO-Code': 'GN',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Guinea Bissau',
    'ISO-Code': 'GW',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Guyana',
    'ISO-Code': 'GY',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Haiti',
    'ISO-Code': 'HT',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Heard und McDonald Islands',
    'ISO-Code': 'HM',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Honduras',
    'ISO-Code': 'HN',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Hong Kong',
    'ISO-Code': 'HK',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Indien',
    'ISO-Code': 'IN',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Indonesien',
    'ISO-Code': 'ID',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Irak',
    'ISO-Code': 'IQ',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Iran',
    'ISO-Code': 'IR',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Irland',
    'ISO-Code': 'IE',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Island',
    'ISO-Code': 'IS',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Israel',
    'ISO-Code': 'IL',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Italien',
    'ISO-Code': 'IT',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Jamaika',
    'ISO-Code': 'JM',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Japan',
    'ISO-Code': 'JP',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Jemen',
    'ISO-Code': 'YE',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Jordanien',
    'ISO-Code': 'JO',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Jugoslawien',
    'ISO-Code': 'YU',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Kaiman Inseln',
    'ISO-Code': 'KY',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Kambodscha',
    'ISO-Code': 'KH',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Kamerun',
    'ISO-Code': 'CM',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Kanada',
    'ISO-Code': 'CA',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Kap Verde',
    'ISO-Code': 'CV',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Kasachstan',
    'ISO-Code': 'KZ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Kenia',
    'ISO-Code': 'KE',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Kirgisistan',
    'ISO-Code': 'KG',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Kiribati',
    'ISO-Code': 'KI',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Kokosinseln',
    'ISO-Code': 'CC',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Kolumbien',
    'ISO-Code': 'CO',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Komoren',
    'ISO-Code': 'KM',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Kongo',
    'ISO-Code': 'CG',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Demokratische Republik Kongo',
    'ISO-Code': 'CD',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Kroatien',
    'ISO-Code': 'HR',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Kuba',
    'ISO-Code': 'CU',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Kuwait',
    'ISO-Code': 'KW',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Laos',
    'ISO-Code': 'LA',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Lesotho',
    'ISO-Code': 'LS',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Lettland',
    'ISO-Code': 'LV',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Libanon',
    'ISO-Code': 'LB',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Liberia',
    'ISO-Code': 'LR',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Libyen',
    'ISO-Code': 'LY',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Liechtenstein',
    'ISO-Code': 'LI',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Litauen',
    'ISO-Code': 'LT',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Luxemburg',
    'ISO-Code': 'LU',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Macao',
    'ISO-Code': 'MO',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Madagaskar',
    'ISO-Code': 'MG',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Malawi',
    'ISO-Code': 'MW',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Malaysia',
    'ISO-Code': 'MY',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Malediven',
    'ISO-Code': 'MV',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Mali',
    'ISO-Code': 'ML',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Malta',
    'ISO-Code': 'MT',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Marianen',
    'ISO-Code': 'MP',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Marokko',
    'ISO-Code': 'MA',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Marshall Inseln',
    'ISO-Code': 'MH',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Martinique',
    'ISO-Code': 'MQ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Mauretanien',
    'ISO-Code': 'MR',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Mauritius',
    'ISO-Code': 'MU',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Mayotte',
    'ISO-Code': 'YT',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Mazedonien',
    'ISO-Code': 'MK',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Mexiko',
    'ISO-Code': 'MX',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Mikronesien',
    'ISO-Code': 'FM',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Mocambique',
    'ISO-Code': 'MZ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Moldavien',
    'ISO-Code': 'MD',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Monaco',
    'ISO-Code': 'MC',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Mongolei',
    'ISO-Code': 'MN',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Montserrat',
    'ISO-Code': 'MS',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Namibia',
    'ISO-Code': 'NA',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Nauru',
    'ISO-Code': 'NR',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Nepal',
    'ISO-Code': 'NP',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Neukaledonien',
    'ISO-Code': 'NC',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Neuseeland',
    'ISO-Code': 'NZ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Nicaragua',
    'ISO-Code': 'NI',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Niederlande',
    'ISO-Code': 'NL',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Niederländische Antillen',
    'ISO-Code': 'AN',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Niger',
    'ISO-Code': 'NE',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Nigeria',
    'ISO-Code': 'NG',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Niue',
    'ISO-Code': 'NU',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Nord Korea',
    'ISO-Code': 'KP',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Norfolk Inseln',
    'ISO-Code': 'NF',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Norwegen',
    'ISO-Code': 'NO',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Oman',
    'ISO-Code': 'OM',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Österreich',
    'ISO-Code': 'AT',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Pakistan',
    'ISO-Code': 'PK',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Palästina',
    'ISO-Code': 'PS',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Palau',
    'ISO-Code': 'PW',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Panama',
    'ISO-Code': 'PA',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Papua Neuguinea',
    'ISO-Code': 'PG',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Paraguay',
    'ISO-Code': 'PY',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Peru',
    'ISO-Code': 'PE',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Philippinen',
    'ISO-Code': 'PH',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Pitcairn',
    'ISO-Code': 'PN',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Polen',
    'ISO-Code': 'PL',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Portugal',
    'ISO-Code': 'PT',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Puerto Rico',
    'ISO-Code': 'PR',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Qatar',
    'ISO-Code': 'QA',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Reunion',
    'ISO-Code': 'RE',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Ruanda',
    'ISO-Code': 'RW',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Rumänien',
    'ISO-Code': 'RO',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Russland',
    'ISO-Code': 'RU',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Saint Lucia',
    'ISO-Code': 'LC',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Sambia',
    'ISO-Code': 'ZM',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Samoa',
    'ISO-Code': 'AS',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Samoa',
    'ISO-Code': 'WS',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'San Marino',
    'ISO-Code': 'SM',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Sao Tome',
    'ISO-Code': 'ST',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Saudi Arabien',
    'ISO-Code': 'SA',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Schweden',
    'ISO-Code': 'SE',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Schweiz',
    'ISO-Code': 'CH',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Senegal',
    'ISO-Code': 'SN',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Seychellen',
    'ISO-Code': 'SC',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Sierra Leone',
    'ISO-Code': 'SL',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Singapur',
    'ISO-Code': 'SG',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Slowakei',
    'ISO-Code': 'SK',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Slowenien',
    'ISO-Code': 'SI',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Solomon Inseln',
    'ISO-Code': 'SB',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Somalia',
    'ISO-Code': 'SO',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Südgeorgien und die Südlichen Sandwichinseln',
    'ISO-Code': 'GS',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Spanien',
    'ISO-Code': 'ES',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Sri Lanka',
    'ISO-Code': 'LK',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'St. Helena',
    'ISO-Code': 'SH',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'St. Kitts Nevis Anguilla',
    'ISO-Code': 'KN',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'St. Pierre und Miquelon',
    'ISO-Code': 'PM',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'St. Vincent',
    'ISO-Code': 'VC',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Süd Korea',
    'ISO-Code': 'KR',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Südafrika',
    'ISO-Code': 'ZA',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Sudan',
    'ISO-Code': 'SD',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Surinam',
    'ISO-Code': 'SR',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Svalbard und Jan Mayen Islands',
    'ISO-Code': 'SJ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Swasiland',
    'ISO-Code': 'SZ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Syrien',
    'ISO-Code': 'SY',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Tadschikistan',
    'ISO-Code': 'TJ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Taiwan',
    'ISO-Code': 'TW',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Tansania',
    'ISO-Code': 'TZ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Thailand',
    'ISO-Code': 'TH',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Timor',
    'ISO-Code': 'TP',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Togo',
    'ISO-Code': 'TG',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Tokelau',
    'ISO-Code': 'TK',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Tonga',
    'ISO-Code': 'TO',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Trinidad Tobago',
    'ISO-Code': 'TT',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Tschad',
    'ISO-Code': 'TD',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Tschechische Republik',
    'ISO-Code': 'CZ',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Tunesien',
    'ISO-Code': 'TN',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Türkei',
    'ISO-Code': 'TR',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Turkmenistan',
    'ISO-Code': 'TM',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Turks und Kaikos Inseln',
    'ISO-Code': 'TC',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Tuvalu',
    'ISO-Code': 'TV',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Uganda',
    'ISO-Code': 'UG',
    Aussteuerung: 'X',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Ukraine',
    'ISO-Code': 'UA',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Ungarn',
    'ISO-Code': 'HU',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Uruguay',
    'ISO-Code': 'UY',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Usbekistan',
    'ISO-Code': 'UZ',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Vanuatu',
    'ISO-Code': 'VU',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Vatikan',
    'ISO-Code': 'VA',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Venezuela',
    'ISO-Code': 'VE',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Vereinigte Arabische Emirate',
    'ISO-Code': 'AE',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Vereinigte Staaten von Amerika',
    'ISO-Code': 'US',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Vietnam',
    'ISO-Code': 'VN',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Virgin Island (Brit.)',
    'ISO-Code': 'VG',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Virgin Island (USA)',
    'ISO-Code': 'VI',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Wallis et Futuna',
    'ISO-Code': 'WF',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Weissrussland',
    'ISO-Code': 'BY',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
  {
    Land: 'Westsahara',
    'ISO-Code': 'EH',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Zentralafrikanische Republik',
    'ISO-Code': 'CF',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Zimbabwe',
    'ISO-Code': 'ZW',
    Aussteuerung: '',
    Geltungsbereich: 'Welt',
  },
  {
    Land: 'Zypern',
    'ISO-Code': 'CY',
    Aussteuerung: '',
    Geltungsbereich: 'Europa',
  },
];
